html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: "sofia-pro" !important;
  -webkit-font-smoothing: antialiased;
  background-color: var(--colors-white);
  --colors-primary-blue: #1f48e7;
  --colors-secondary-dark-blue: #052d69;
  --colors-secondary-light-blue: #22b3f2;
  --colors-secondary-navy-blue: #0d2481;
  --colors-secondary-very-light-purple: #edf0fd;
  --colors-secondary-purple: #8058ff;
  --colors-secondary-light-purple: #e5deff;
  --colors-secondary-dark-purple: #4a27b9;
  --colors-secondary-black: #010a21;
  --colors-secondary-cyan: #00cccc;
  --colors-white: #ffffff;
  --colors-background-gray: #fafafa;
  --colors-very-light-gray: #e8e8e8;
  --colors-box-shadow-gray: #9999;
  --colors-fade-to-grey: #f0f0f0;
  --colors-light-gray: #c4c4c4;
  --colors-smokey-white: #f6f5f6;
  --colors-dark-gray: #545454;
  --colors-sandstone-gray: #716c7b;
  --colors-blue-black: #120a23;
  --colors-black: #000000;
  --colors-navy-purple: #2e273d;
  --colors-functional-red: #cc0000;
  --colors-functional-blue: #1890ff;
  --colors-functional-orange: #fa9614;
  --colors-functional-green: #16b757;
  --colors-eiffel-blue: #0064ac;
  --colors-big-sky: #ebf3f8;
  --colors-info-blue: #024a8c;
  --colors-info-blue-light: #e3f3ff;
  --colors-light-grey: #f3f3f3;
  --colors-alert-red: #d90000;
  --colors-alert-red-light: #ffe5e5;
  --colors-success-green-light: #e7f6e6;
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: "sofia-pro" !important;
}

h1 {
  font-size: 44px;
}

h1.ant-typography {
  font-weight: 900;
  font-size: 44px;
}
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  font-weight: 900;
}

section {
  background: var(--colors-white);
}

button,
input {
  font-family: "sofia-pro", sans-serif;
}
.ant-btn > .anticon + span {
  margin-left: 0;
}

.ant-layout {
  background-color: var(--colors-background-gray);
}

.ant-layout-content {
  display: flex;
}

.ant-row {
  flex: 1;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}
.ant-drawer-wrapper-body {
  background-color: #e4e5e5;
}

/*
  Tab overrides
*/

.ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 0;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold !important;
  color: var(--colors-black) !important;
}

.ant-tabs-tab-btn:hover,
.ant-tabs-tab:hover {
  color: var(--colors-black);
}
.ant-tabs-tab-btn:focus {
  color: var(--colors-black);
}
.ant-tabs-ink-bar {
  height: 0px !important;
}

.ant-tabs-nav-wrap {
  box-shadow: 0px -3px 6px 1px #777;
  background-color: var(--colors-white);
}

.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0px;
}

/*
   * Input overrides
   */

.ant-input,
.ant-picker,
.ant-input-password,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border-top: 0;
  font-size: 16px;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  outline: none;
  min-height: 38px;
  height: 56px;
  border: 0.0625rem solid var(--colors-very-light-gray);
  border-radius: 0.25rem;
}

.ant-picker {
  border: 0.0625rem solid var(--colors-light-gray);
}

.ant-picker-dropdown {
  z-index: 1052;
}

.ant-space-compact {
  width: clamp(300px, 680px, 680px);
}

.ant-picker,
.ant-space-vertical {
  width: 100% !important;
}

.ant-picker input {
  border-radius: 0px;
}

.ant-select .ant-select-single .nt-select-show-arrow {
  border: 0px;
}

.ant-input:not(:focus),
.ant-input-password:not(.ant-input-affix-wrapper-focused),
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--colors-light-gray);
}

.ant-form-item-has-error .ant-input {
  border-color: var(--colors-functional-red) !important;
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: var(--colors-functional-red) !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: var(--colors-functional-red) !important;
  font-weight: 400;
  font-size: 16px !important;
}

.ant-input:focus,
.ant-input-affix-wrapper-focused,
.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper-focused,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
  outline: none;
}

/* Bump up the size of the show password icon */
.ant-input-password .ant-input-suffix {
  font-size: 1.3rem;
}

/* Select dropdown styles */

.ant-select {
  height: 3.125rem;
  border-radius: 4px;
  font-size: 1rem !important;
}

.ant-select-selection-search-input {
  margin-top: 1rem !important;
}

.ant-select-dropdown {
  border-radius: 4px !important;
  border: 1px solid var(--colors-black) !important;
  z-index: 1052 !important;
}

.ant-select-dropdown .ant-select-item-option,
.ant-select-dropdown .ant-select-item-option-content {
  color: var(--colors-black);
}

.ant-select-selection-item {
  padding-top: 0.825rem !important;
}

.ant-select-arrow {
  width: 1rem;
  color: var(--colors-dark-gray);
}

.ant-select-selector {
  box-shadow: none !important;
  height: 3.125rem !important;
}

.ant-select-item-option-content {
  border-radius: 0.625rem;
  padding: 0.45rem 0.2rem 0.45rem 0.2rem;
}

@media only screen and (min-width: 1200px) and (max-height: 768px) {
  .ant-select-lg {
    font-size: 14px;
  }
}

/* Checkbox styles */

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--colors-secondary-light-blue);
  border-color: var(--colors-secondary-light-blue);
}
.ant-checkbox .ant-checkbox-inner {
  width: 1.5rem;
  height: 1.5rem;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 0;
  padding-top: 20px;
  padding-bottom: 10px;
}
.ant-checkbox-inner {
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  background-color: var(--colors-white);
  border: 1px solid var(--colors-light-gray);
  border-radius: 4px;
}

.ant-checkbox-wrapper:focus {
  background: var(--colors-secondary-light-blue);
  border-color: var(--colors-secondary-light-gray) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--colors-secondary-light-blue);
  border-color: var(--colors-secondary-light-blue);
  border-radius: 3px !important;
}

.ant-checkbox-checked::after {
  border-color: var(--colors-secondary-light-blue);
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  top: 55%;
  transform: rotate(45deg) scale(1.5) translate(-50%, -50%);
  border-color: var(--colors-white) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  background-color: var(--colors-secondary-light-blue);
  border-color: var(--colors-secondary-light-blue);
}

.ant-checkbox-disabled .ant-checkbox-inner {
  border: 1px solid var(--colors-light-gray) !important;
  background-color: var(--colors-light-gray);
}

.ant-checkbox-disabled:hover .ant-checkbox-inner {
  border: 1px solid var(--colors-light-gray) !important;
  background-color: var(--colors-light-gray);
}

.ant-checkbox-disabled .ant-checkbox-input {
  cursor: default;
  background-color: var(--colors-light-gray);
}

.ant-checkbox-inner::after {
  margin-left: 0.125rem;
}

/*
   * Card overrides
   */

.ant-card {
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3),
    -6px -6px 10px rgba(255, 255, 255, 0.6);
  padding: 1.25rem;
}

@media only screen and (min-width: 1200px) and (max-height: 768px) {
  .ant-card-body {
    padding: 12px 24px 0px 24px;
  }
}

.ant-card-head-title {
  font-size: 1.15rem;
  font-weight: bold;
  padding: 0;
  text-align: center;
}
/*
TODO: Remove/fix this
*/
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

/*
   * Button overrides
   */

.ant-btn {
  transition: background-color 0.1s linear;
  border-radius: 0;
}

.ant-btn.ant-btn-primary:active {
  background-color: var(--colors-primary-blue) !important;
  box-shadow: inset 1px 10px 5px var(--colors-secondary-dark-blue),
    inset -1px -10px 5px var(--colors-primary-blue);
}

.ant-btn.ant-btn-primary:not(:disabled):hover {
  background-color: var(--colors-secondary-navy-blue);
}

.ant-btn:disabled,
.ant-btn:disabled:hover {
  box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.3),
    -6px -6px 4px rgba(255, 255, 255, 0.6);
}

/*
   * Form Item overrides 
   */
@media only screen and (min-width: 1200px) and (max-height: 768px) {
  .ant-form-item {
    font-size: 0.75rem;
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 3.5px;
  }

  .ant-form-item-explain,
  .ant-form-item-label > label,
  .ant-form-item-label > input,
  .ant-form-item-control-input-content > label {
    font-size: 0.75rem;
  }
}

/*
 * Tooltip overrides
 */

.ant-popover-inner {
  border-radius: 8px;
}

.ant-popover-title {
  font-family: "sofia-pro" !important;
  color: var(--colors-blue-black);
}

.ant-popover-inner-content {
  padding: 0 16px;
}

.ant-tooltip {
  font-size: 0.75rem;
  font-weight: 700;
  font-family: "sofia-pro";
}

.ant-tooltip .ant-tooltip-inner {
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  min-height: 1rem;
}

.ant-tooltip-inner {
  box-shadow: none;
}

.custom-tooltip {
  left: 3rem !important;
}

.custom-tooltip .ant-tooltip-content .ant-tooltip-inner {
  height: 42px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--colors-secondary-purple);
  color: var(--colors-white);
}

.custom-tooltip-active .ant-tooltip-arrow .ant-tooltip-arrow-content,
.custom-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  height: 20px;
  width: 20px;
  left: 4px;
  background-color: var(--colors-secondary-purple) !important;
  color: var(--color-white);
}

/*
   * Miscellaneous overrides
   */
.ant-menu.ant-menu-dark {
  background: var(--colors-secondary-black);
}

.ant-layout-sider {
  z-index: 9999999;
}

.ant-layout-sider-children {
  background: var(--colors-secondary-black);
  width: inherit;
}

.ant-layout-sider-children {
  position: fixed;
}

.ant-menu-item {
  padding: 0 !important;
  height: 56px !important;
  margin-top: 0 !important;
  align-items: start !important;
  margin-bottom: 0 !important;
  position: static !important;
  margin-inline: 0 !important;
  width: 100% !important;
  border-radius: 0 !important;
}

.ant-menu-item:first-child {
  margin-bottom: 8px !important;
}

.ant-menu-item:last-child {
  position: absolute;
  bottom: 0;
}
.ant-dropdown-menu {
  border-radius: 0.2rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 0.313rem 0.125rem,
    rgba(0, 0, 0, 0.08) 0px 0.375rem 1rem 0px,
    rgba(0, 0, 0, 0.05) 0px 0.563rem 1.75rem;
  min-width: 175px;
  max-width: 175px;
}

.ant-dropdown-menu-item {
  border-radius: 1rem;
  padding: 0 !important;
}
.ant-dropdown .ant-dropdown-menu {
  padding: 0;
  border-radius: 0;
}
.ant-modal-mask {
  z-index: 1052 !important;
}

.ant-modal-wrap {
  z-index: 1053 !important;
}

/*
   * Notification overrides
*/

.ant-notification {
  font-family: "sofia-pro" !important;
  z-index: 1051 !important;
}
.ant-pagination .ant-pagination-item {
  border-radius: 1rem;
}
.ant-notification-notice {
  background-color: var(--colors-secondary-black);
}

.ant-notification-notice-message {
  color: var(--colors-secondary-white);
  font-size: 0.875rem !important;
  margin-right: 0 !important;
}

.ant-notification-notice-message p {
  margin-bottom: 0 !important;
  font-weight: bold;
}

.ant-notification-notice-description {
  color: var(--colors-secondary-white);
  font-size: 0.875rem !important;
  margin-right: 0 !important;
  margin-top: 0.3rem !important;
}

.ant-notification-notice-close {
  display: flex !important;
}

@media only screen and (min-width: 992px) {
  .ant-notification-notice {
    width: 380px;
    padding-left: 0 !important;
  }

  .ant-notification-notice-icon {
    margin-left: -0.5rem !important;
    margin-top: -0.2rem !important;
    transform: inherit !important;
    top: auto !important;
  }

  .ant-notification-notice-with-icon {
    margin-left: 0;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message,
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 1rem !important;
  }

  .ant-notification-notice-message {
    margin-top: 0.45rem !important;
    width: 300px !important;
    max-width: 18rem;
  }

  .ant-notification-notice-description {
    width: 280px !important;
  }

  .ant-notification-notice-description p {
    margin-bottom: 0 !important;
    font-weight: 200 !important;
  }

  .ant-notification-notice-close {
    top: auto !important;
    display: flex !important;
  }
}

@media only screen and (max-width: 992px) {
  .ant-notification .ant-notification-notice-icon {
    margin-bottom: 0rem;
    padding: 0 !important;
  }

  .ant-notification .ant-notification-notice {
    padding: 0;
  }

  .ant-notification-notice-close {
    top: 0.5rem;
    right: 0.5rem;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--colors-light-gray) !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: var(--colors-secondary-light-blue) !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: var(--colors-light-gray) !important;
}

.ant-radio-inner {
  border-color: var(--colors-light-gray) !important;
  background-color: #fff !important;
}

.ant-radio-inner::after {
  position: fixed;
  transform: scale(0.6) !important;
}

.ant-radio,
.ant-checkbox-input {
  transform: scale(1.5);
}

.ant-menu-dark .ant-menu-item-selected {
  background: none;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background-color: var(--colors-secondary-navy-blue);
}

.ant-upload-wrapper .ant-upload-drag {
  border: none;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
  background-color: #fff;
  padding: 0;
}
.ant-modal-content {
  padding: 0 !important;
  font-size: 16px;
}

.ant-collapse-expand-icon {
  display: flex;
  align-self: center;
  width: 32px;
  padding-inline-end: 0 !important;
}
.ant-tag {
  border-radius: 1rem;
}
.ant-modal .ant-modal-close {
  background-color: #fff !important;
}

.ant-select-dropdown-placement-bottomLeft {
  padding: 0 !important;
}
.ant-picker .ant-picker-input > input {
  font-size: 16px !important;
}
.ant-drawer .ant-drawer-header {
  background-color: var(--colors-secondary-black);
}
.ant-tabs-left > .ant-tabs-content-holder {
  border-left: none;
}
