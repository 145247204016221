.ant-notification {
  left: 50%;
  right: auto !important;
  transform: translateX(-50%);
}

.ant-notification-notice {
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 4px;
  color: #fff;
  width: 160px;
  min-height: 160px;
  line-height: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 0.9em;
}

.ant-notification-notice-icon {
  margin-left: 0px;
  position: initial;
}

@media only screen and (min-width: 992px) {
  .copy-link-toast {
    min-height: 50px !important;
    width: 250px !important;
  }

  .download-track-toast {
    min-height: 50px !important;
    width: 300px !important;
    padding: 0.625rem 1.5rem !important;
  }

  .copy-link-toast .ant-notification-notice-content {
    width: 200px !important;
  }

  .copy-link-toast .ant-notification-notice-close,
  .download-track-toast .copy-link-toast .ant-notification-notice-close {
    display: none !important;
  }

  .download-track-toast .ant-notification-notice-close,
  .download-track-toast .copy-link-toast .ant-notification-notice-close {
    display: none !important;
  }

  .copy-link-toast
    .ant-notification-notice-with-icon
    .ant-notification-notice-message,
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 2rem !important;
    margin-top: 0.3rem !important;
    padding-top: 0.1rem;
  }

  .download-track-toast
    .ant-notification-notice-with-icon
    .ant-notification-notice-message,
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    width: 200px !important;
    margin-left: 5rem !important;
  }

  .copy-link-toast .ant-notification-notice-icon {
    margin-left: -0.2rem !important;
    margin-top: -0.3rem !important;
  }

  .download-track-toast .ant-notification-notice-icon {
    margin-left: 1.8rem !important;
    margin-top: -0.4rem !important;
  }
}

.ant-notification-notice-with-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ant-notification-notice-message {
  width: 130px;
  line-height: 0.9em;
  margin-top: 0.2rem;
}

.ant-notification-notice-description {
  width: 160px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message,
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 0px;
  padding-right: 0px;
}

.success-toast-notification {
  min-height: 35px !important;
  padding-right: 0 !important;
  width: 280px !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.success-toast-notification .ant-notification-notice-content {
  width: 280px !important;
}

.success-toast-notification .ant-notification-notice-close {
  display: none !important;
}

.error-toast-notification .ant-notification-notice-content {
  margin-top: 0.9375rem;
}

.error-toast-notification .ant-notification-notice-close {
  display: initial;
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
}

@media only screen and (min-width: 992px) {
  .ant-notification.ant-notification-bottomRight {
    right: 0px !important;
    bottom: 150px !important;
    left: initial;
    transform: initial;
  }

  .ant-notification-notice {
    min-height: 75px;
    width: 300px;
  }

  .ant-notification-notice-with-icon {
    width: 300px;
    margin-left: 1.25rem;
    height: initial;
    display: block;
    text-align: initial;
    line-height: 1.25rem;
  }

  .ant-notification-notice-icon {
    max-height: 48px;
    position: absolute;
    margin-left: 0.5rem;
    transform: translateY(-50%);
    top: 50%;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message,
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 4.25rem;
    margin-bottom: 0px;
  }

  .ant-notification-notice-message {
    width: 220px;
    margin-top: 0px;
  }

  .ant-notification-notice-description {
    width: 200px;
  }

  .error-toast-notification {
    width: 360px;
  }

  .error-toast-notification .ant-notification-notice-with-icon {
    width: 320px;
  }

  .error-toast-notification .ant-notification-notice-with-icon {
    margin-left: 0px;
  }

  .error-toast-notification .ant-notification-notice-content {
    margin-top: 0px;
    margin-left: 1.2rem;
  }

  .error-toast-notification .ant-notification-notice-close {
    display: initial;
    position: absolute;
    right: 1.4rem;
    margin-top: 0.2rem;
    top: 50%;
  }
}

@media only screen and (max-width: 992px) {
  .ant-notification {
    bottom: auto !important;
    top: 40% !important;
    margin: 0;
    overflow: hidden;
  }

  .success-toast-notification {
    min-height: 150px !important;
    padding-right: 0 !important;
    width: 160px !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .ant-notification .ant-notification-notice {
    min-height: 160px;
    padding: 1.5rem;
    width: 160px;
  }

  .ant-notification .ant-notification-notice-content {
    width: 100% !important;
  }

  .ant-notification .ant-notification-notice-with-icon,
  .ant-notification .ant-notification-notice-message {
    margin-left: 0;
    width: 100%;
  }

  .ant-notification-notice-with-icon {
    width: 300px;
    margin-left: 1.25rem;
    height: initial;
    display: block;
    line-height: 1.25rem;
  }

  .ant-notification .ant-notification-notice-icon {
    display: block;
    margin: 0 auto 1rem auto;
    position: relative;
    top: 0;
    transform: none;
    width: 48px;
  }

  .ant-notification .ant-notification-notice-with-icon,
  .ant-notification .ant-notification-notice-message {
    margin-left: 0;
  }

  .ant-notification-fade-appear.ant-notification-fade-appear-active {
    -webkit-animation: fadeIn linear 0.4s;
    animation: fadeIn linear 0.4s;
  }

  .ant-notification-fade-leave.ant-notification-fade-leave-active {
    -webkit-animation: fadeOut linear 0.4s;
    animation: fadeOut linear 0.4s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

#atlwdg-trigger {
  z-index: 1051;
}
